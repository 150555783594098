<template>
  <div class="balance-transfer-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Selecionar filtros</p>
      </header>
      <section class="modal-card-body">
        <div class="row">
          <div class="col-6">
            <b-field label="Ordenar por">
              <b-select v-model="selectedOrderBy" expanded>
                <option value="asc,name">Nome</option>
                <option value="desc,schedules_count">
                  Quantidade de consultas
                </option>
                <option value="desc,schedules_sum">
                  Valor total das consultas
                </option>
                <option value="desc,patient_review.review_rating">
                  Avaliação 
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="col-6">
            <app-input
              label="Resultados por página"
              v-model="selectedPerPage"
              type="number"
              min="1"
            ></app-input>
          </div>
        </div>
      </section>

      <footer class="modal-card-foot is-right">
        <b-button @click="$emit('close', false)">Cancelar</b-button>
        <b-button @click="applyFilter" type="is-primary"
          >Filtrar consultas</b-button
        >
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    orderBy: {
      type: String,
    },
    perPage: {
      type: Number,
      default: () => 10,
    },
  },

  data: () => ({
    selectedPerPage: null,
    selectedOrderBy: null,
  }),
  methods: {
    applyFilter() {
      const filters = {
        sorter: this.selectedOrderBy,
        perPage: this.selectedPerPage,
      };

      this.$emit('close', filters);
    },
  },
  beforeMount() {
    this.selectedOrderBy = this.orderBy;
    this.selectedPerPage = this.perPage;
  },
};
</script>

<style lang="scss" scoped></style>
