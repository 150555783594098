<template>
  <div class="patient-list-view">
    <app-header icon="users" title="Pacientes"></app-header>

    <section>
      <div class="card">
        <header class="card-header">
          <div class="card-header-content">
            <div class="row">
              <div class="col-5">
                <app-input
                  v-model="term"
                  placeholder="Buscar..."
                  label="Filtrar por nome, email, documento ou telefone"
                  icon="search"
                >
                </app-input>
              </div>
              <div class="col-3">
                <b-field label="Filtrar por tipo">
                  <b-select v-model="internal" expanded>
                    <option value="">Ambos</option>
                    <option value="external">Externo</option>
                    <option value="internal">Interno</option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>
          <div class="card-header-buttons">
            <b-tooltip label="Selecionar mais filtros" class="mr-2">
              <b-button
                @click="onFilterModalClick"
                type="is-dark"
                size="is-small"
                icon-left="plus"
              >
                Opções de filtros
              </b-button>
            </b-tooltip>

            <router-link :to="{ name: 'patients.create' }">
              <b-tooltip label="Novo Paciente">
                <b-button type="is-primary" size="is-small" icon-left="plus">
                  Adicionar
                </b-button>
              </b-tooltip>
            </router-link>

            <b-tooltip label="Importar por planilha" class="ml-2">
              <b-button
                @click="importPatients()"
                type="is-dark"
                size="is-small"
                icon-left="file-upload"
              >
                Importar
              </b-button>
            </b-tooltip>

            <b-tooltip label="Exportar planilha de pacientes" class="ml-2">
              <b-button
                :disabled="!data.length"
                @click="exportPatients()"
                type="is-dark"
                size="is-small"
                icon-left="file-excel"
              >
                Exportar
              </b-button>
            </b-tooltip>
          </div>
        </header>

        <div class="card-content">
          <div class="row"></div>
          <b-table
            @page-change="onPageChange"
            :per-page="perPage"
            :loading="isLoading"
            :total="total"
            :data="data"
            paginated
            backend-pagination
            aria-page-label="Página"
            aria-next-label="Próxima Página"
            aria-current-label="Página Atual"
            aria-previous-label="Página Anterior"
          >
            <b-table-column v-slot="props" field="patient" label="Nome">
              {{ props.row.name }}
            </b-table-column>

            <b-table-column v-slot="props" field="patient" label="Telefone">
              {{
                props.row.phone
                  ? props.row.phone.replace(
                      /^(\d{2})(\d{4})(\d{4})/,
                      '($1) $2-$3'
                    )
                  : ''
              }}
            </b-table-column>

            <b-table-column v-slot="props" field="cpf" label="CPF">
              {{
                props.row.cpf
                  | mask(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
              }}
            </b-table-column>

            <b-table-column v-slot="props" field="city" label="E-mail">
              {{ props.row.user.email ? props.row.user.email : '-' }}
            </b-table-column>

            <b-table-column
              :visible="isDoctor"
              v-slot="props"
              field="review_rating"
              label="Avaliação"
            >
              <star-rating
                :star-size="20"
                :clearable="true"
                :show-rating="false"
                :rating="patientRating(props.row)"
                @rating-selected="setReviewRating(props.row, $event)"
              >
              </star-rating>
            </b-table-column>

            <b-table-column v-slot="props" field="city" label="Tipo">
              {{ props.row.doctor_id ? 'Interno' : 'Externo' }}
            </b-table-column>

            <b-table-column v-slot="props" field="name" label="Qtd. Consultas">
              {{ props.row.schedules_count }}
            </b-table-column>
            <b-table-column v-slot="props" field="name" label="Valor R$">
              R$
              {{ toMoney(props.row.schedules_sum) }}
            </b-table-column>
            <b-table-column v-slot="props" label="Opções" width="120">
              <router-link
                :to="{
                  name: 'patients.edit',
                  params: { id: props.row.id },
                }"
              >
                <b-tooltip
                  :label="props.row.is_editable ? 'Editar' : 'Visualizar'"
                >
                  <b-button
                    type="is-secondary"
                    size="is-small"
                    :icon-right="props.row.is_editable ? 'pencil-alt' : 'eye'"
                  />
                </b-tooltip>
              </router-link>

              <b-tooltip
                v-if="props.row.doctor_id"
                label="Excluir paciente"
                style="margin-left: 10px"
              >
                <b-button
                  size="is-small"
                  type="is-danger"
                  icon-right="trash-alt"
                  @click="onPatientDeleteClick(props.row.id)"
                />
              </b-tooltip>
            </b-table-column>

            <template slot="footer">
              <div class="row">
                <div class="col-12">
                  <div class="has-text-right">
                    <div>
                      <b>Valor conforme os filtros:</b>
                      <br />
                    </div>

                    <div>
                      <b>Total de pacientes: {{ total }}</b>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="frown" size="is-large"></b-icon>
                  </p>
                  <p>Nenhum paciente encontrado.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PatientReviewsService from '@/services/patient-reviews.service';
import PatientsService from '@/services/patients.service';
import PatientImportModal from '@/modals/Imports/PatientImportModal.vue';
import debounce from 'lodash/debounce';
import flattenDeep from 'lodash/flattenDeep';
import StarRating from 'vue-star-rating';
import PatientsFilterModal from '../../modals/PatientsFilterModal.vue';

export default {
  data: () => ({
    isLoading: true,
    isDoctor: false,
    data: [],
    term: '',
    internal: '',
    total: 0,
    page: 1,
    perPage: 10,
    sorter: 'asc,name',
    doctorId: null,
  }),
  components: {
    StarRating,
  },
  watch: {
    term: debounce(function () {
      this.page = 1;
      this.loadPatients();
    }, 400),
    internal: debounce(function () {
      this.page = 1;
      this.loadPatients();
    }, 400),
    sorter: debounce(function () {
      this.page = 1;
      this.loadPatients();
    }, 400),
    perPage() {
      if (this.perPage > this.data.length) this.loadPatients();
    },
  },
  methods: {
    getProceduresValues(patient) {
      let resultArray = [];
      if (patient.schedules) {
        patient.schedules.forEach(function (schedule) {
          if (schedule.procedure) {
            resultArray[schedule.procedure.name] =
              typeof resultArray[schedule.procedure.name] === 'undefined'
                ? parseFloat(schedule.value)
                : resultArray[schedule.procedure.name] +
                  parseFloat(schedule.value);
          } else {
            resultArray['Sem procedimento'] =
              typeof resultArray['Sem procedimento'] === 'undefined'
                ? parseFloat(schedule.value)
                : resultArray['Sem procedimento'] + parseFloat(schedule.value);
          }
        });
      }
      let result = '';
      for (var key in resultArray) {
        result +=
          '<p>' +
          key +
          ': R$ ' +
          parseFloat(resultArray[key])
            .toFixed(2)
            .replace(',', '.')
            .replace('.', ',') +
          '</p>';
      }
      return result;
    },
    loadPatients() {
      this.isLoading = true;

      PatientsService.get({
        page: this.page,
        perPage: this.perPage,
        'filter[name_email_document_or_phone]': this.term,
        'filter[internal]': this.internal,
        sort: this.sorter,
      })
        .then(({ data }) => {
          const { total, data: items, current_page } = data;

          this.total = total;
          this.data = Object.values(items);
          this.page = current_page;
        })
        .finally(() => (this.isLoading = false));
    },
    onPageChange(page) {
      this.page = page;
      this.loadPatients();
    },
    importPatients() {
      this.$buefy.modal.open({
        parent: this,
        component: PatientImportModal,
        trapFocus: true,
        hasModalCard: true,
        canCancel: ['x'],
        events: {
          close: (data) => data && this.loadPatients(),
        },
      });
    },
    setReviewRating(patient, rating) {
      this.isLoading = true;
      this.errors = {};

      const data = {
        patient_id: patient.id,
        doctor_id: this.doctorId || patient.doctor_id,
        review_rating: patient.patient_review
          ? patient.patient_review.review_rating
          : rating,
      };

      const promise = patient.patient_review
        ? PatientReviewsService.update(patient.patient_review.id, data)
        : PatientReviewsService.store(data);

      promise
        .then(() => {
          this.$buefy.snackbar.open('Avaliação salva com sucesso.');
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
          const { status, data } = response;

          if (status === 422) this.errors = flattenDeep(data);
          else
            this.$buefy.snackbar.open(
              data.message || 'Avaliação salva com sucesso.'
            );
        });
    },
    deleteReviewRating(id) {
      this.isLoading = true;

      PatientReviewsService.destroy(id)
        .then(() => {
          this.$buefy.snackbar.open('Avaliação excluída com sucesso.');
          this.isLoading = false;
        })
        .catch(({ response }) => {
          if (typeof response != 'undefined' && response.status != 200) {
            this.$buefy.snackbar.open(
              response.message || 'Erro ao tentar excluir avaliação.',
              'error',
              4000
            );
          }
        });
    },
    exportPatients() {
      PatientsService.export({
        term: this.searchTerm,
      }).then(({ data }) => {
        const fileName = data.split('/')[data.split('/').length - 1];
        const fileLink = document.createElement('a');

        fileLink.href = data;
        fileLink.setAttribute('download', fileName);
        fileLink.setAttribute('style', 'display:none;');

        document.body.appendChild(fileLink);
        fileLink.click();

        fileLink.remove();
      });
    },
    toMoney(value) {
      return value
        ? parseFloat(value).toFixed(2).replace(',', '.').replace('.', ',')
        : (0).toFixed(2).replace(',', '.').replace('.', ',');
    },
    patientRating(patient) {
      return patient.patient_review ? patient.patient_review.review_rating : 0;
    },

    onPatientDeleteClick(patientId) {
      this.$buefy.dialog.confirm({
        message: 'Deseja realmente excluir esse paciente?',
        onConfirm: () => this.deletePatient(patientId),
      });
    },
    deletePatient(patientId) {
      PatientsService.destroy(patientId)
        .then(() => {
          this.$buefy.snackbar.open('Paciente excluído com sucesso!');
          this.loadPatients();
        })
        .catch(({ response }) => {
          const { data, status } = response;

          if (status === 403)
            this.$buefy.snackbar.open({
              message: data.message,
              type: 'is-danger',
              position: 'is-bottom',
            });
          else
            this.$buefy.snackbar.open({
              message: 'Erro inesperado ao excluído paciente',
              type: 'is-danger',
              position: 'is-bottom',
            });
        });
    },
    onFilterModalClick() {
      this.$buefy.modal.open({
        parent: this,
        component: PatientsFilterModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          perPage: Number(this.perPage),
          orderBy: this.sorter,
        },
        events: {
          close: (data) => data && this.applyFilters(data),
        },
      });
    },
    applyFilters(filters = {}) {
      Object.keys(filters).forEach((key) => {
        if (key == 'sorter') this.sorter = filters[key];
        if (key == 'perPage') this.perPage = filters[key];
      });

      this.loadPatients();
    },
  },
  mounted() {
    this.isDoctor = this.$store.getters.doctorId ? true : false;
    if (this.isDoctor) this.doctorId = this.$store.getters.doctorId;
    this.loadPatients();
  },
};
</script>
