<template>
  <div class="patient-import-modal modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Importar Pacientes</p>
      <button type="button" class="delete" @click="$emit('close')" />
    </header>
    <section class="modal-card-body">
      <b-notification type="is-secondary" aria-close-label="Fechar">
        A importação deve ser realizada com base em uma planilha modelo.
        <br />
        <a :href="modelUrl" target="_blank">Baixe o modelo clicando aqui.</a>
      </b-notification>

      <app-file-picker
        @fileSelected="onFileSelect"
        @fileDeleted="onFileDelete"
        label="Arquivo .xlsx"
        accept=".xlsx,.xls"
      ></app-file-picker>

      <ul v-if="errors.length" class="errors-container custom-scroll">
        <li v-for="(row, index) in errors" :key="index" class="error">
          Linha {{ row.row }}:
          <div class="error__list">
            <p v-for="(error, errIdx) in row.errors" :key="`err-${errIdx}`">
              {{ error }}
            </p>
          </div>
        </li>
      </ul>

      <b-button
        @click="uploadFile()"
        :loading="isLoading"
        :disabled="!file || isLoading"
        type="is-primary"
        size="is-small"
        class="mt-4"
      >
        Importar
      </b-button>
    </section>
    <footer class="modal-card-foot is-right">
      <b-button @click="$parent.close()"> Fechar </b-button>
    </footer>
  </div>
</template>

<script>
import PatientsService from '@/services/patients.service';

export default {
  data: () => ({
    isLoading: false,
    file: null,
    errors: [],
    modelUrl: `${process.env.VUE_APP_BASE_URL}/imports/model?model=patients`,
  }),
  methods: {
    onFileSelect(file) {
      this.file = file;
    },
    onFileDelete() {
      this.file = null;
      this.errors = [];
    },
    uploadFile() {
      this.isLoading = true;
      this.errors = [];

      PatientsService.import(this.file)
        .then(({ data }) => {
          const { count } = data;

          this.$buefy.snackbar.open(
            `${count} paciente(s) importado(s) com sucesso!`
          );
          this.$emit('close', true);
        })
        .catch(({ response }) => {
          const { status, data } = response;

          if (status === 422) this.errors = [...data];
          else
            this.$buefy.snackbar.open(
              data.message || 'Erro ao realizar importação.',
              'error'
            );
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.errors-container {
  margin: 16px 0;
  padding: 12px;
  max-height: 150px;
  overflow-y: scroll;
  background: #f5f5f5;

  .error {
    &__list {
      padding-left: 15px;
    }
  }
}
</style>
